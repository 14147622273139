<template>
    <div class="how-detail">
        <span style="font-size:20px">作业：</span>
        <div class="hwk-content">听写听写听写听写听写听写听写听写听写听写听写听写听写听写听</div>
        <div style="padding-bottom:10px;">
            <van-button type="primary" @click="submit">提交作业</van-button>
        </div>

        <div class="scan-documents">
            <!-- 文件上传控件，可上传图片、pdf、word、excel -->
            <van-uploader
                :after-read="afterRead"
                multiple
                :max-size="2 * 1024 * 1024"
                @oversize="onOversize"
                accept="image/jpeg, image/png, image/jpg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
            >
                <van-button icon="plus" type="primary" color="#0070FF">上传作业</van-button>
            </van-uploader>
            <!-- 上传文件列表 -->
            <div class="file-list" v-if="fileList.length > 0">
                <span class="file-li" v-for="(file, idx) of fileList" :key="file.filePath">
                    <i class="iconfont iconfujian"></i>
                    <van-image width="100" height="100" :src="file.filePath" />
                    <span>{{ file.fileName }}</span>
                    <van-button icon="plus" type="primary" color="#0070FF" @click="handleRemoveFile(idx)">删除</van-button>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { getImageUrl } from '@/api/upload.service'
import Exif from 'exif-js'
import Toast from 'vant/lib/toast'
export default {
    data() {
        return {
            fileList: [],
            toast: null,
            loading: false,
            sType: '', //科目
            sData: ''
        }
    },
    methods: {
        submit() {
            console.log('in.........', this.fileList)
        },
        // 文件上传
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            if (file.length) {
                //判断是否选择了多个文件，如果是的话，循环遍历单个文件上传
                file.forEach(f => {
                    this.uploadFile(f)
                })
            } else {
                this.uploadFile(file)
            }
        },
        // 上传文件到服务器
        uploadFile(file) {
            this.toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '上传中'
            })
            getImageUrl(file.file, this.guid())
                .then(data => {
                    // 手动清除 Toast
                    Toast.clear()
                    if (data) {
                        this.fileList.push({
                            fileName: file.file.name,
                            filePath: data.data.fileurl,
                            fileType: file.file.type || 'file'
                        })
                        this.$message.success('上传成功！')
                    } else {
                        this.$message.error('上传失败')
                    }
                })
                .catch(() => {
                    Toast.clear()
                })
        },
        // 移除上传文件
        handleRemoveFile(idx) {
            this.fileList.splice(idx, 1)
        },
        fileSaved(url) {
            console.log(url, 'url.1111111111')
        },
        guid() {
            return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
        },
        S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        }
    }
}
</script>
<style lang="less" scoped>
.uploader-tip {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
}
.file-list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .file-li {
        display: flex;
        line-height: 24px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
        color: rgba(51, 51, 51, 0.65);
        .iconfujian {
            margin-right: 10px;
            color: #999;
            font-size: 14px;
        }
        span {
            display: inline-block;
            flex: 1;
            // .text Overflow;
        }
        .iconclose-circle {
            margin-left: 12px;
            font-size: 16px;
            color: #999;
        }
    }
}
</style>
