var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "how-detail" }, [
    _c("span", { staticStyle: { "font-size": "20px" } }, [_vm._v("作业：")]),
    _c("div", { staticClass: "hwk-content" }, [
      _vm._v("听写听写听写听写听写听写听写听写听写听写听写听写听写听写听")
    ]),
    _c(
      "div",
      { staticStyle: { "padding-bottom": "10px" } },
      [
        _c(
          "van-button",
          { attrs: { type: "primary" }, on: { click: _vm.submit } },
          [_vm._v("提交作业")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "scan-documents" },
      [
        _c(
          "van-uploader",
          {
            attrs: {
              "after-read": _vm.afterRead,
              multiple: "",
              "max-size": 2 * 1024 * 1024,
              accept:
                "image/jpeg, image/png, image/jpg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
            },
            on: { oversize: _vm.onOversize }
          },
          [
            _c(
              "van-button",
              { attrs: { icon: "plus", type: "primary", color: "#0070FF" } },
              [_vm._v("上传作业")]
            )
          ],
          1
        ),
        _vm.fileList.length > 0
          ? _c(
              "div",
              { staticClass: "file-list" },
              _vm._l(_vm.fileList, function(file, idx) {
                return _c(
                  "span",
                  { key: file.filePath, staticClass: "file-li" },
                  [
                    _c("i", { staticClass: "iconfont iconfujian" }),
                    _c("van-image", {
                      attrs: { width: "100", height: "100", src: file.filePath }
                    }),
                    _c("span", [_vm._v(_vm._s(file.fileName))]),
                    _c(
                      "van-button",
                      {
                        attrs: {
                          icon: "plus",
                          type: "primary",
                          color: "#0070FF"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleRemoveFile(idx)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }