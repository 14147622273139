import axios from 'axios'


export function getImageUrl (file, id) {
  let url = '/v1/files/fileupload'
  // url = 'http://localhost:18286/files/fileupload'
  let d = new Date()
  let key = {
    token: window.localStorage.getItem('token'),

    source: 501
  }
  let data = new FormData()
  data.append('file', file)
  data.append('name', file.name)
  data.append('fid', id)
  data.append('dfn', d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate())
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      async: false, // fasle表示同步请求，true表示异步请求
      headers: {
        'Source': 501,
        'Content-Type': 'multipart/form-data'
      },
      data: data
    }).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
}
